import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_SPECIAL_PRODUCTS_SUCCESS,
  GET_SPECIAL_PRODUCTS_FAILURE,
  GET_NEW_ARRIVALS_SUCCESS,
  GET_NEW_ARRIVALS_FAILURE,
  GET_BEST_SELLERS_SUCCESS,
  GET_BEST_SELLERS_FAILURE,
  GET_LABELED_PRODUCTS_SUCCESS,
  GET_LABELED_PRODUCTS_FAILURE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  data: [], // this is used to store the list of the products
  product: {}, // this is used to store the single product
  specialProducts: [], // this is used to store the list of the special products
  newArrivals: [], // this is used to store the list of the new arrivals
  bestSellers: [], // this is used to store the list of the best sellers
  labeledProducts: {
  },
  error: "",
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case GET_SPECIAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        specialProducts: action.payload.data,
      };
    case GET_SPECIAL_PRODUCTS_FAILURE:
      return {
        ...state,
        specialProducts: action.payload.error,
      };   
      case GET_BEST_SELLERS_SUCCESS:
      return {
        ...state,
        bestSellers: action.payload.data,
      };
    case GET_BEST_SELLERS_FAILURE:
      return {
        ...state,
        bestSellers: action.payload.error,
      };
      case GET_NEW_ARRIVALS_SUCCESS:
        return {
          ...state,
          newArrivals: action.payload.data,
        };
      case GET_NEW_ARRIVALS_FAILURE:
        return {
          ...state,
          newArrivals: action.payload.error,
        };
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, product: action.payload.data };
    case GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
      case GET_LABELED_PRODUCTS_SUCCESS:
        return {
          ...state,
          labeledProducts: {
            ...state.labeledProducts,
            [action.payload.label]: action.payload.data
          }
        }
      case GET_LABELED_PRODUCTS_FAILURE:
        return {
          ...state, error: action.payload.error
        }
    default:
      return state;
  }
}
