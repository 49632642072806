import React from "react";
import useStyles from "./styles";

export default function OrderSummary({
  totalQuantity,
  totalPrice,
  redirect,
  shippingCharges,
  loading,
  buttonText,
  order,
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.orderSummary}>
        <div className={classes.orderHeader}>
          <h3 className={classes.title}>Order Summary</h3>
        </div>
        <div className={classes.orderBody}>
          <div className={classes.priceSec}>
            <div className={classes.priceCol}>Total Quantity</div>
            <div className={classes.priceCol}>{totalQuantity()}</div>
          </div>
          <div className={classes.priceSec}>
            <div className={classes.priceCol}>Sub Total</div>
            <div className={classes.priceCol}>
              Rs. {totalPrice().toFixed(2)}
            </div>
          </div>

          {order?.coupon_code && (
            <div className={classes.priceSec}>
              <div className={classes.priceCol}>
                Coupon Discount ({order?.coupon_code})
              </div>
              <div className={classes.discountCol}>
                Rs. -{order?.coupon_discount_amount.toFixed(2)}
              </div>
            </div>
          )}
          <div className={classes.priceSec}>
            <div className={classes.priceCol}>Shipping Charges</div>
            <div className={`${classes.priceCol} ${classes.shippingCharge}`}>
              {shippingCharges}
            </div>
          </div>

          <div className={classes.priceSec}>
            <div className={classes.priceCol}>Total</div>
            {order?.total_amount ? (
              <div className={classes.priceCol}>
                Rs. {order.total_amount.toFixed(2)}
              </div>
            ) : (
              <div className={classes.priceCol}>
                  Rs. {totalPrice().toFixed(2)}
              </div>
            )}
          </div>
        </div>
        <div onClick={redirect} className={classes.btnSec}>
          <span>{loading ? "Loading..." : buttonText}</span>
        </div>
      </div>
    </>
  );
}
