

export const apiUrl = "https://metcraftchitra.com/backend/api/web";
export const dataUrl = "https://metcraftchitra.com/backend/data";
export const capitalize = (str) => `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`;

export const sortFn = ((el1, el2, sortKey = 'display_order') => el1[sortKey] > el2[sortKey] ? 1 : -1);

export const imageUrl = (url, prefix = 'products', sizePrefix = '120x120-') => `${dataUrl}/${prefix}/${sizePrefix}${url}`;

export const productImageUrl = (url, prefix = 'products', id = "1", sizePrefix = '120x120-') => `${dataUrl}/${prefix}/${id}/${sizePrefix}${url}`;

export const testimonialimageUrl = (url, prefix = 'products') => `${dataUrl}/${prefix}/${url}`;

export const convertToSlug = (text) => text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')

export const getContentByName = (contents, name, defaultValue) => contents.find(content => content.name === name) || defaultValue;

export const filterByCategoryName = (contents, categoryName, defaultValue) => contents.filter(content => content.category === categoryName) || defaultValue;


export const getContentByCategoryName = (contents, categoryName, defaultValue) => contents.find(content => content.category === categoryName) || defaultValue;

export const getSettingByKey = (settings, keyName) => settings.find(setting => setting.key === keyName);

export const getTestimonialByName = (clienttestimonials, company, defaultValue) => clienttestimonials.find(clienttestimonial => clienttestimonial.company === company) || defaultValue;

export const getTestimonialByCompanyName = (clienttestimonials, companyName) => clienttestimonials.filter(clienttestimonial => clienttestimonial.company === companyName);

export const getCategoryByParentId = (categories, parentId) => categories.filter(category => parseInt(category.parent_id) === parseInt(parentId) );

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator) => {

    let arr = [];

    if (value) {
        arr = value.split(seperator).filter(Boolean).map(v => v.trim()).filter(v => v !== "");
    }
    return arr;
}


// check img exists or not in the given url
export const checkImgExists = (url) => {
    var img = new Image();
    img.src = url;
}

//  check img exists or not in the given url
export function imageExists(image_url) {
    var http = new XMLHttpRequest();

    http.open("HEAD", image_url, false);
    http.send();

    return http.status !== 404;
  }

  // it is used to get the image url from the given html.
// export const getImageUrl = (html) => {
//     let img = html.match(/<img.*?src="(.*?)"/);
//     // let regex = /src="(.*?)"/i;
//     // let match = regex.exec(html);
//     return match[1];
// }

export const getImageUrl = (html) => { 
    let img = html.match(/<img.*?src="(.*?)"/);
    return img[1];
}

export const customerId_local_storage_key = 'avon_customerId';
export const authKey_local_storage_key = 'avon_authToken';
export const cartData_local_storage_key = 'avon_cart_data';
export const userInfo_local_storage_key = 'avon_userInfo';



export const getCategoryLink = (category) => `/products/${category.id}/1/${category.url_slug}`;

export const getMenusByCategory = (menus, categoryName) => menus.filter(menu => menu.category === categoryName);

export const getCategoryByName = (categories, name) => {
    return categories.find(category => category.name.toLowerCase() === name?.toLowerCase());
}

export const getCategoriesByParentName = (categories, parentName) => {
    let parentId = getCategoryByName(categories, parentName)?.id;
    return categories.filter(category => parseInt(category.parent_id) === parseInt(parentId));
}