import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import "./index.css";
import { dataUrl, productImageUrl, toArray } from "../../helpers/common";
import parse from "html-react-parser";

export default function ProductDetailModal({
  openProductModal,
  setOpenProductModal,
  productModalRef,
  currOpenedProduct,
}) {
  const style = {
    position: "absolute",
    top: "0",
    left: "0",
    padding: "0 !important",
    width: "100%",
    height: "100%",
    transition: "all 0.5s ease-in-out",
    outline: "none",
    display: "flex",
    alignItems: "center",
  };
  const [openedImg, setOpenedImg] = useState("");
  const [thumbs, setThumbs] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);

  const getList = (list) => {
    let listArr = toArray(list, "\n");
    return listArr.map((h) => <li key={h.id}>{h}</li>);
  };

  const asyncCallsOnLoad = useCallback(async () => {
    try {
      const data = currOpenedProduct;

      let media = data.media;
      setOpenedImg(data.main_image);

      if (media) {
        const thumbs = [];

        let mediaArr = toArray(media, ",");

        mediaArr.forEach((m) => {
          thumbs.push({
            img: m,
          });
        });

        setThumbs(thumbs);
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  }, [currOpenedProduct]);

  useEffect(() => {
    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);

  console.log("thumbs", thumbs);
  console.log("currOpenedProduct", currOpenedProduct);

  const changeImage = (image) => {
    setImgLoading(true);
    setTimeout(() => {
      setImgLoading(false);
    }, 500);
    setOpenedImg(image);
  };

  return (
    <Modal
      open={openProductModal}
      onClose={() => setOpenProductModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openProductModal}>
        <Box sx={style}>
          <div className="my-container product-detail-modal">
            <div
              onClick={() => setOpenProductModal(false)}
              className="closeButton"
            >
              <img src="/images/close.png" alt="" />
            </div>
            <div ref={productModalRef} className="inner_container row">
              <div className="col-md-6 left-col">
                {imgLoading ? (
                  "loading"
                ) : (
                  <img
                    className="product-img img-fluid"
                    src={productImageUrl(
                      openedImg,
                      "products",
                      currOpenedProduct?.id,
                      "406x406-"
                    )}
                    alt="product-img"
                  />
                )}

                {thumbs.length > 1 && (
                  <div className="product-info">
                    {thumbs.map((item, index) => (
                      <div
                        key={"thumb-" + index}
                        onClick={() => changeImage(item.img)}
                      >
                        <img
                          src={`${dataUrl}/products/${currOpenedProduct.id}/196x196-${item.img}`}
                          alt="product images"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-md-6 right-col">
                <h4>{currOpenedProduct?.name}</h4>

                {currOpenedProduct?.description && (
                  <p>{parse(currOpenedProduct?.description)}</p>
                )}

                {currOpenedProduct?.brand_name && (
                  <div className="brand_name">
                    <span>Brand Name:</span> {currOpenedProduct?.brand_name}
                  </div>
                )}

                {currOpenedProduct?.highlights ? (
                  <>
                    <h5>Product Highlights</h5>
                    <ul className="listing_info">
                      {getList(currOpenedProduct?.highlights)}
                    </ul>
                  </>
                ) : null}

                {currOpenedProduct?.attributes ? (
                  <>
                    <h5>Product Attributes</h5>
                    <ul className="listing_info">
                      {getList(currOpenedProduct?.attributes)}
                    </ul>
                  </>
                ) : null}
                {/* <div className="price-sec">
                  <h5>Rs.{currOpenedProduct?.selling_price}</h5>
                  <span>Rs.{currOpenedProduct?.mrp}</span>
                </div> */}
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
