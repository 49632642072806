import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ProductLayout from "../ProductLayout";

import ProductContainer from "../ProductContainer";

import { getProducts } from "../../actions/products";


import { useParams } from "react-router-dom";

export default function ProductListing(props) {
  // getting category id from the url
  // const categoryId = parseInt(props.match.params.categoryId);
  const { categoryId } = useParams();

  const categories = useSelector((state) => state.categories.data);
  const currentCategory = categories.find(
    (category) => category.id === categoryId
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [loading, setLoading] = useState(true);
  const [recordFound, setRecordFound] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setRecordFound(true);

    window.scrollTo({
      top: 60,
      behavior: 'smooth',
    })

    dispatch(
      getProducts(
        `ProductsSearch[category_id]=${categoryId}&sort=display_order&per-page=200`
      )
    ).then((result) => {
      if (result?.payload?.data?.length === 0) {
        setRecordFound(false);
      }
      setLoading(false);
    });
  }, [dispatch, categoryId, currentCategory]);

  return (
    <ProductLayout parentClass="list_sec" categoryId={categoryId}>
      <ProductContainer loading={loading} recordFound={recordFound} />
    </ProductLayout>
  );
}
