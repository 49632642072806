import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";
import colors from "../../helpers/colors";

const useStyles = makeStyles((theme) => ({
  inputForm: {
    marginBottom: 20,
  },
  shippingSec: {
    padding: "50px 0",
  },
  heading: {
    textAlign: "center",
    marginBottom: 60,
  },
  shippingFeild: {
    padding: "10px 0 !important",
  },
  btnSec: {
    marginTop: 25,
  },
  btnLink: {
    padding: "8px 30px",
    color: "#fff",
    display: "inline-block",
    borderRadius: 5,
    background: COLORS.primary,
    transition: 'all 0.3s ease-in-out',
    "&:hover": {
      color: "#fff",
      background: COLORS.secondary,
    },
  },

  cartItemContainer: {
    border: "1px solid #666",
    borderRadius: 6,
    alignItems: "center",
  },
  shippingBlock: {
    marginBottom: 20,
    alignItems: "flex-start",
    "@media (max-width: 767px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  errorText: {
    color: "red",
    fontSize: 13,
  },
  images: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    border: "1px solid #aaa",
    borderRadius: 6,
  },
  products: {
    "& > *": {
      maxWidth: 1200,
      margin: "auto",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: 16,
    "&:hover": {
      color: colors.primary,
    },
  },
}));

export default useStyles;
