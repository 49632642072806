import React, { useEffect, useRef, useState } from "react";
import ProductBox from "./ProductBox";
import { useSelector } from "react-redux";
import useStyles from "../SearchResult/styles";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ProductDetailModal from "../ProductDetailModal";

export default function ProductContainer(props) {
  const classes = useStyles();
  const products = useSelector((state) => state.products.data);
  const { categoryName } = useParams();
  const [openProductModal, setOpenProductModal] = useState(false);
  const [currOpenedProduct, setCurrOpenedProduct] = useState(null);
  const productModalRef = useRef();
  const openModal = (product) => {
    setOpenProductModal(true);
    setCurrOpenedProduct(product);
  };

  useEffect(() => {
    const handler = (event) => {
      if (openProductModal) {
        if (!productModalRef.current.contains(event.target)) {
          setOpenProductModal(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const cartData = useSelector((state) => state.cart.data);

  let loader = (
    <div className={classes.loaderSec}>
      <img
        className={classes.loader}
        src="/images/loader.gif"
        alt="loading..."
      />
      loading...
    </div>
  );
  let notFound = (
    <h5 className="text-danger">Sorry! Record not found in this category</h5>
  );

  let productList = (
    <div className="row">
      {products.map((product, index) => (
        <ProductBox
          openModal={openModal}
          key={"product-" + product.id}
          product={product}
          cartData={cartData}
          index={index}
        />
      ))}
    </div>
  );

  return (
    <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
      <ProductDetailModal
        openProductModal={openProductModal}
        setOpenProductModal={setOpenProductModal}
        productModalRef={productModalRef}
        currOpenedProduct={currOpenedProduct}
        setCurrOpenedProduct={setCurrOpenedProduct}
      />
      <motion.h4
        initial={{ opacity: 0, x: 400 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="title"
      >
        {categoryName}
      </motion.h4>
      {props.recordFound || notFound}

      {props.loading ? loader : productList}

      {/* <div className="pagination_info">
        <ul>
          <li className="disable prev"><a href="#">&lt; Prev</a></li>
          <li className="active"><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li className="next"><a href="#">Next &gt;</a></li>
        </ul>
      </div> */}
    </div>
  );
}
