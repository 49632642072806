import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AboutMet from "./AboutMet";
import Banner from "./Banner";
import ChooseUs from "./ChooseUs";
import Expertise from "./Expertise";
import ReadMore from "./ReadMore";
import { setHomePage } from "../../actions/meta";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    dispatch(setHomePage(true));

    return () => {
      dispatch(setHomePage(false));
    };
  }, [dispatch]);

  return (
    <>
      <Banner />
      <AboutMet />
      <ChooseUs />
      <ReadMore />
      <Expertise />
    </>
  );
};

export default HomePage;
