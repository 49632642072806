import React from "react";
import { Link } from "react-router-dom";
import { imageUrl, convertToSlug } from "../../../helpers/common";

const CategoryBox = ({ category }) => {
  const link = `/products/${category.id}/1/${convertToSlug(category.name)}`;

  return (
    <Link to={link} className={`categories-box categoriesBox`}>
      <img
        className="boxImage"
        src={imageUrl(category.image, "categories")}
        alt="category-img"
      />
      <p className="categoryName">{category.name}</p>
    </Link>
  );
};

export default CategoryBox;
