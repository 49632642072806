import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SearchModal from "./SearchModal";
import ProductDetailModal from "../../ProductDetailModal";
import DropdownMenu from "./DropdownMenu";
import SideBar from "../SideBar";
import {useSelector} from 'react-redux';
import {dataUrl} from '../../../helpers/common'

const Header = () => {
  const [openSearch, setOpenSearch] = React.useState(false);
  const searchRef = useRef();
  const searchResultRef = useRef();
  const [openProductModal, setOpenProductModal] = useState(false);
  const [currOpenedProduct, setCurrOpenedProduct] = useState(null);
  const [subMenuVisible, setSubMenuVisible] = useState(false);
  const productModalRef = useRef();
  const [openSideBar, setOpenSideBar] = useState(false);
  const assets = useSelector(state => state.assets.data)
  const websiteLogo = assets.find(e => e.name === "website_logo");

  const sideBarRef = useRef();
  const openProModal = (product) => {
    setOpenProductModal(true);
    setCurrOpenedProduct(product);
  };

  const openModal = (product) => {
    setOpenProductModal(true);
    setCurrOpenedProduct(product);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const handler = (event) => {
      if (!sideBarRef.current.contains(event.target)) {
        setOpenSideBar(false);
      }
      if (openSearch) {
        if (
          !searchRef.current.contains(event.target) &&
          !searchResultRef.current.contains(event.target)
        ) {
          setOpenSearch(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const logoClicked = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="nav-sec">
      <SideBar
        sideBarRef={sideBarRef}
        openSideBar={openSideBar}
        setOpenSideBar={setOpenSideBar}
        openModal={openModal}
      />
      <SearchModal
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        searchRef={searchRef}
        searchResultRef={searchResultRef}
        openProModal={openProModal}
      />
      <ProductDetailModal
        openProductModal={openProductModal}
        setOpenProductModal={setOpenProductModal}
        productModalRef={productModalRef}
        currOpenedProduct={currOpenedProduct}
        setCurrOpenedProduct={setCurrOpenedProduct}
      />
      <header className="header">
        <div className="container">
          <div className="row v-center">
            <div className="header-item item-left">
              <Link onClick={logoClicked} className="navbar-brand" to="/">
                <img className="img-fluid" src={`${dataUrl}/assets/${websiteLogo?.file_name}`} alt="" />
              </Link>
            </div>
            {/* menu start here */}
            <div className="header-item item-center">
              <div className={`menu-overlay`}></div>
              <nav className={`menu`}>
                <div
                  className={`mobile-menu-head ${
                    subMenuVisible ? "active" : ""
                  }`}
                >
                  <div className="go-back">
                    <i className="fa fa-angle-left"></i>
                  </div>
                  <div className="current-menu-title"></div>
                  <div className="mobile-menu-close">&times;</div>
                </div>
                <DropdownMenu
                  openModal={openModal}
                  setSubMenuVisible={setSubMenuVisible}
                />
              </nav>
            </div>
            {/* menu end here */}
            <div className="header-item item-right">
              <div className="search-block" onClick={() => setOpenSearch(true)}>
                <i className="fa fa-search" aria-hidden="true" />
              </div>
              <button
                className={`navbar-toggler navbar-toggler-right ${
                  openSideBar ? "" : "collapsed"
                }`}
                type="button"
                data-toggle="collapse"
                data-target="#Navigation"
                aria-controls="Navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setOpenSideBar(!openSideBar)}
                // ref={menuBtnRef}
              >
                <span />
                <span />
                <span />
              </button>
              <div className="btn-sec shop-btn">
                <Link
                  onClick={() =>
                    openInNewTab("https://shop.metcraftchitra.com/")
                  }
                  className="btn-link"
                  to={"#"}
                >
                  <i className="fa fa-shopping-basket" aria-hidden="true" />
                  <span>Shop</span>
                </Link>
              </div>
              {/* mobile menu trigger */}
              {/* <div className="mobile-menu-trigger">
                <span />
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
