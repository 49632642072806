import React from "react";
import { Link } from "react-router-dom";
import { imageUrl } from "../../../helpers/common";

const CategoryBox = ({ product, openModal }) => {

  return (
    <Link to={"#"} onClick={() => openModal(product)}>
      <div className={`categories-box categoriesBox`}>
        <img
          className="boxImage"
          src={imageUrl(product.main_image, `products/${product.id}`)}
          alt="product-img"
        />
        <p className="productName">{product.name}</p>
        {/* <p className="productPrice">Rs.{product.selling_price}</p> */}
      </div>
    </Link>
  );
};

export default CategoryBox;
