import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import "./index.css";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { searchProducts } from "../../../../actions/products";
import { productImageUrl } from "../../../../helpers/common";
export default function SearchModal({
  openSearch,
  setOpenSearch,
  searchRef,
  searchResultRef,
  openProModal,
}) {
  const style = {
    position: "absolute",
    top: "0",
    left: "0",
    padding: "0 !important",
    width: "100%",
    height: "100%",
    transition: "all 0.5s ease-in-out",
    outline: "none",
  };
  const [searchKeyword, setSearchKeyword] = useState("");

  const [search, setSearch] = useState({
    result: [],
    state: "Idle",
  });

  const handleClose = () => {
    if (searchKeyword.length === 0) {
      setOpenSearch(false);
    } else {
      setSearchKeyword("");
    }
  };

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setSearch({
        result: [],
        state: "Idle",
      });
    }
    if (searchKeyword) {
      setSearch({
        result: [],
        state: "Searching",
      });
    }

    const searchOnType = async () => {
      if (searchKeyword) {
        let result = await searchProducts(searchKeyword);
        setSearch({
          result: result.data,
          state: "SearchFinished",
        });
      }
    };
    const timerId = setTimeout(() => {
      searchOnType();
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchKeyword]);

  const productPlaceholder = (
    <div className="placeholder-sec">
      <RectShape
        color="rgb(0, 0, 0, 0.2)"
        style={{ width: 105, height: 90, borderRadius: 5 }}
      />
      <div className="text-block-sec">
        <TextBlock rows={1} color="rgb(0, 0, 0, 0.2)" />
        <TextBlock rows={1} color="rgb(0, 0, 0, 0.2)" />
      </div>
    </div>
  );

  // var content = [];

  if (search.state === "Searching") {
    var content = (
      <div ref={searchResultRef} className="search-result">
        {[1, 2, 3, 4].map((e) => (
          <ReactPlaceholder
            type="media"
            ready={false}
            rows={2}
            showLoadingAnimation={true}
            customPlaceholder={productPlaceholder}
          ></ReactPlaceholder>
        ))}
      </div>
    );
  }

  if (search.state === "SearchFinished") {
    if (search.result.length > 0) {
      content = (
        <div ref={searchResultRef} className="search-result">
          {search?.result?.map((data) => (
            <div
              key={data.id}
              onClick={() => {
                setOpenSearch(false);
                openProModal(data);
              }}
              className="search-result-item"
            >
              <div className="search-result-item-img">
                <img
                  src={productImageUrl(data.main_image, "products", data.id)}
                  alt=""
                />
              </div>
              <div className="search-result-item-content">
                <h5>{data.name}</h5>
                {/* <div className="price-sec">
                  <h6>Rs.{data.selling_price}</h6>
                  <span>Rs.{data.mrp}</span>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      content = (
        <div ref={searchResultRef} className="search-result">
          <div className="no-result-found">
            <img src="/images/not-found.jpg" className="img-fluid" alt="" />
            <h5>no result found</h5>
          </div>
        </div>
      );
    }
  }

  return (
    <Modal
      open={openSearch}
      onClose={() => setOpenSearch(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openSearch}>
        <Box sx={style}>
          <div className="my-container">
            <div className="input-sec" ref={searchRef}>
              <div className="search-block">
                <i className="fa fa-search" />
              </div>
              <input
                type="text"
                placeholder="Search..."
                name="search"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <div className="close-block" onClick={handleClose}>
                <img src="/images/close.png" alt="" />
              </div>
            </div>

            {content}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
