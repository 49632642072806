/* eslint-disable linebreak-style */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCustomerDetails, logout } from "./actions/customer";
import Layout from "./components/Layout";
import "./index.css";
import { showErrorSnackbar } from "./actions/snackbar";
import { customerId_local_storage_key } from "./helpers/common";
import "./App.css";
import AppLoader from "./components/AppLoader";
const PATHS = [];

function App() {
  const dispatch = useDispatch();

  const customerId = localStorage.getItem(customerId_local_storage_key);

  useEffect(() => {
    PATHS.forEach((path) => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;

      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerDetails(customerId)).then((res) => {
        if (res?.message?.includes("401") || res?.message?.includes("403")) {
          dispatch(showErrorSnackbar("Session expired. Please login again."));
          dispatch(logout());
        }
      });
    }
  }, [customerId, dispatch]);


  return (
    <>
      <AppLoader />

      <Layout />
    </>
  );
}

export default App;
