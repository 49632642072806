import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getBanners } from "../../../actions/banners";
import { getSettings } from "../../../actions/settings";
import { getMenus } from "../../../actions/menus";
import {
  getSettingByKey,
  authKey_local_storage_key,
} from "../../../helpers/common";
import { getContents } from "../../../actions/contents";
import { getAssets } from "../../../actions/assets";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import { LOAD_CART_DATA } from "../../../constants/actionTypes";
import { subscribeToNewsLetter } from "../../../api/contacts";
import { getCategory } from "../../../actions/categories";
import { getCustomerAddress } from "../../../actions/customer";
import "./index.css";

export default function Footer() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.data);
  //only getting company_contact_number
  const company_contact_number = getSettingByKey(
    settings,
    "company_contact_number"
  );
  const isHomepage = useSelector((state) => state.meta.isHome);

  const company_address = getSettingByKey(settings, "company_address", {});

  //only getting company_email_address
  const company_email_address = getSettingByKey(
    settings,
    "company_email_address"
  );

  //only getting footer_description
  const footer_description = getSettingByKey(settings, "footer_description");

  //only getting footer_copyright_message
  const footer_copyright_message = getSettingByKey(
    settings,
    "footer_copyright_message"
  );

  const [email, setEmail] = useState("");
  const [apiCalled, setApiCalled] = useState(false);

  const token = localStorage.getItem(authKey_local_storage_key);

  const menusDatafooter = useSelector((state) => state.menus.menus);

  const results = menusDatafooter.filter(
    (menu) => menu.category === "footer-main-menu"
  );
  useEffect(() => {
    dispatch({ type: LOAD_CART_DATA });
    dispatch(getBanners("Read More Banners"));
    dispatch(getBanners("Listing Banners"));
    dispatch(getBanners("Business Homepage Banners"));
    dispatch(getSettings());
    dispatch(getMenus());
    dispatch(getContents());
    dispatch(getCategory());
    if (token) {
      dispatch(getCustomerAddress());
    }
    dispatch(getAssets());
    // dispatch(loadCartData());
  }, [dispatch, token]);

  const socialLinkFacebook = getSettingByKey(settings, "social_links_facebook");

  const socialLinkTwitter = getSettingByKey(settings, "social_links_twitter");

  const socialLinkLinkedin = getSettingByKey(settings, "social_links_linkedin");

  const socialLinkPinterest = getSettingByKey(
    settings,
    "social_links_pinterest"
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const subscribeNewsLetter = async (e) => {
    e.preventDefault();

    if (email.length === 0) {
      dispatch(showErrorSnackbar("Please enter email address"));
      return;
    }
    try {
      setApiCalled(true);
      const response = await subscribeToNewsLetter({ email: email });
      if (response.data) {
        dispatch(showSuccessSnackbar(response.data.message));
      }
      setApiCalled(false);
    } catch (err) {
      console.log(">>>", err.response.data.message);
      dispatch(showErrorSnackbar(err.response.data.message));
      // TODO: Handle error
      setApiCalled(false);
    }
  };

  return (
    <footer>
      <div className={`footer-sec  ${isHomepage ? "homepage-footer" : ""}`}>
        <div className="container">
          <div className="block">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="footer-logo">
                  <img
                    className="img-fluid"
                    src="/images/footer-logo.jpg"
                    alt="footer-logo"
                  />
                </div>
                <div className="paragraph-text">
                  <p className="footer-dscr">{footer_description?.value}</p>
                </div>
                <hr className="divider" />
                <div>
                  <ul className="social-icons">
                    {socialLinkFacebook && (
                      <li
                        onClick={() => openInNewTab(socialLinkFacebook?.value)}
                      >
                        <Link className="social-icons-menu" to="#">
                          <i className="fa fa-facebook" />
                        </Link>
                      </li>
                    )}
                    {socialLinkTwitter && (
                      <li
                        onClick={() => openInNewTab(socialLinkTwitter?.value)}
                      >
                        <Link className="social-icons-menu" to="#">
                          <i className="fa fa-twitter" />
                        </Link>
                      </li>
                    )}
                    {socialLinkLinkedin && (
                      <li
                        onClick={() => openInNewTab(socialLinkLinkedin?.value)}
                      >
                        <Link className="social-icons-menu" to="#">
                          <i className="fa fa-linkedin" />
                        </Link>
                      </li>
                    )}
                    {socialLinkPinterest && (
                      <li
                        onClick={() => openInNewTab(socialLinkPinterest?.value)}
                      >
                        <Link className="social-icons-menu" to="#">
                          <i className="fa fa-pinterest" />
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 main-links-col">
                <div>
                  <div className="heading-text">
                    <h4>
                      MAIN <span className="heading-span">LINKS</span>
                    </h4>
                  </div>
                  <div className="footer-heading">
                    <ul className="footer-menu">
                      {results.map((result) => (
                        <li key={result.id}>
                          <Link className="footer-link" to={result.url}>
                            {result.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="heading-text">
                  <h4>
                    CONTACT <span className="heading-span">INFO</span>
                  </h4>
                </div>
                <div className="contact-heading">
                  <ul className="contact-info">
                    <li>
                      <div className="contact-map-marker">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        {company_address?.value}
                      </div>
                    </li>
                    <li>
                      <div className="contact-map-marker">
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <a href={`tel:${company_contact_number?.value}`}>
                          {company_contact_number?.value}
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="contact-map-marker">
                        <i className="fa fa-envelope-o" aria-hidden="true" />
                        <a href={`mailto:${company_email_address?.value}`}>
                          {company_email_address?.value}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* col-md-3*/}
              <div className="col-lg-3 col-md-4">
                <div className="newsletter">
                  <div className="news-let-heading">
                    <h4>NEWSLETTER</h4>
                    <form onSubmit={subscribeNewsLetter}>
                      <div className="email-add">
                        <input
                          className="email-input"
                          type="email"
                          placeholder="Email address..."
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="btn-sec">
                        {!apiCalled ? (
                          <input
                            className="btn-link"
                            type="submit"
                            value="SEND"
                          />
                        ) : (
                          <input
                            className="btn-link"
                            type="submit"
                            value="loading..."
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-sec">
        <p className="copyright-text">{footer_copyright_message?.value}</p>
      </div>
    </footer>
  );
}
