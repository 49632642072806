import React, { useCallback, useEffect } from "react";
import { Grid, TextField, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ShippingDetailsForm = ({
  classes,
  handleSubmit,
  customer,
  errors,
  remarks,
  setRemarks,
  setCustomer,
  setErrors,
}) => {
  const customerState = useSelector((state) => state.customers.profile);

  const handleFieldChange = useCallback(
    async (field, e) => {
      e.persist();
      setCustomer((currData) => {
        return {
          ...currData,
          [field]: e.target.value,
        };
      });
      setErrors((currErr) => {
        return {
          ...currErr,
          [field]: false,
        };
      });
    },
    [setCustomer, setErrors]
  );

  useEffect(() => {
    if (customerState) {
      setCustomer((currVal) => {
        return {
          ...currVal,
          address: customerState.address ? customerState.address : "",
          name: customerState.name ? customerState.name : "",
          mobile_number: customerState.mobile_number
            ? customerState.mobile_number
            : "",
          pin_code: customerState.pin_code ? customerState.pin_code : "",
          city: customerState.city ? customerState.city : "",
          district: customerState.district ? customerState.district : "",
          state: customerState.state ? customerState.state : "",
          additional_mobile_number: customerState.additional_mobile_number
            ? customerState.additional_mobile_number
            : "",
        };
      });
    }
  }, [customerState, setCustomer]);

  return (
    <Box className="temp2" pr={5}>
      <form onSubmit={handleSubmit}>
        <Grid className={classes.inputForm}>
          <TextField
            required
            fullWidth
            variant="standard"
            value={customer.name}
            name="name"
            onChange={handleFieldChange.bind(this, "name")}
            label="Your Name"
            error={errors.name ? true : false}
            className={classes.shippingFeild}
          />
          {errors.name ? (
            <Box className={classes.errorText}>Please enter name</Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <TextField
            multiline
            required
            fullWidth
            variant="standard"
            value={customer.address}
            name="address"
            onChange={handleFieldChange.bind(this, "address")}
            label="Shipping Address"
            error={errors.address ? true : false}
            className={classes.shippingFeild}
          />
          {errors.address ? (
            <Box className={classes.errorText}>
              Please enter your complete address
            </Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <TextField
            value={customer.pin_code}
            variant="standard"
            fullWidth
            label="Your Pin Code *"
            inputProps={{ length: 6 }}
            onChange={handleFieldChange.bind(this, "pin_code")}
            type="number"
            error={errors.pin_code ? true : false}
            className={classes.shippingFeild}
          />
          {errors.pin_code ? (
            <Box className={classes.errorText}>
              {" "}
              Sorry! We do not deliver the product at the given pin code. Please{" "}
              <a
                target="_blank"
                href="/pages/available-pincodes"
                style={{ color: "blue" }}
              >
                click here
              </a>{" "}
              to know the pin codes where we can deliver the products.
            </Box>
          ) : null}
        </Grid>

        <Grid className={classes.inputForm}>
          <TextField
            value={customer.additional_mobile_number}
            fullWidth
            error={errors.additional_mobile_number ? true : false}
            className={classes.shippingFeild}
            label="Enter Your Additional Phone Number"
            onChange={handleFieldChange.bind(this, "additional_mobile_number")}
            variant="standard"
          />
          {errors.additional_mobile_number ? (
            <Box className={classes.errorText}>
              Please enter a valid additional mobile number
            </Box>
          ) : null}
        </Grid>

        <TextField
          label="Your Remark"
          variant="standard"
          value={remarks}
          className={classes.shippingFeild}
          fullWidth
          placeholder="Anything you want to add along with delivery ?"
          onChange={(e) => setRemarks(e.target.value)}
        />

        {/* <div className="check-btn">
          <Link className="go-cart-btn" to="/cart">
            <i className="fa fa-arrow-left"></i> Go Back
          </Link>
        </div> */}

        <div className={classes.btnSec}>
          <Link className={classes.btnLink} to="/cart"> 
          <i className="fa fa-arrow-left"></i> Go Back</Link>
        </div>
      </form>
    </Box>
  );
};

export default ShippingDetailsForm;
