import ajax from "./ajax";

export function getItemList(itemName, queryParamsObj, perPage = 200, page) {
  return ajax(`/${itemName}?${queryParamsObj}?&per-page=${perPage}`);
}

export function getProduct(productId) {
  return ajax(`/products/${productId}`);
}

export function getSimilarProducts(productId) {
  return ajax(`/products?ProductsSearch[similar_of]=${productId}`);
}

export function postReview(params) {
  return ajax("/product_reviews", { method: "POST", data: params });
}

export function getAllReviews(productId) {
  return ajax(`/product_reviews?product_id=${productId}`);
}

export function fetchProductsByConditions(conditions) {
  return ajax(`/products?&per-page=200&page=1&${conditions}`);
}

export function getCouponCodeList() {
  return ajax('/coupons?sort=display_order&per-page=15&page=1');
}

export function getSearchList(params, perPage, page) {
  let url = `/products${params}&per-page=${perPage}&page=${page}`;
  return ajax(url);
}