import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.css";
import { dataUrl } from "../../../helpers/common";
import parse from 'html-react-parser';

const ChooseUs = () => {
  const assets = useSelector((state) => state.assets.data);
  const contents = useSelector((state) => state.contents.data);
  const chooseUs = contents.find(e => e.name === 'CHOOSE US')

  const choose_us_image1 = assets.find(
    (data) => data.name === "choose_us_image1"
  );
  const choose_us_image2 = assets.find(
    (data) => data.name === "choose_us_image2"
  );
  const choose_us_image3 = assets.find(
    (data) => data.name === "choose_us_image3"
  );

  return (
    <>
      <div className="choose-us-sec my-sec">
        <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex">
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                      delay: 0.2,
                      duration: 2,
                      type: "spring",
                    }}
                    className="my-col ml-0"
                  >
                    {choose_us_image1 && (
                      <img
                        className="img-fluid"
                        src={`${dataUrl}/assets/${choose_us_image1.file_name}`}
                        alt=""
                      />
                    )}
                  </motion.div>
                  <div className="second-img-col">
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: 0.4,
                        duration: 2,
                        type: "spring",
                      }}
                      className="my-col"
                    >
                      {choose_us_image2 && (
                        <img
                          className="img-fluid"
                          src={`${dataUrl}/assets/${choose_us_image2.file_name}`}
                          alt=""
                        />
                      )}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        delay: 0.6,
                        duration: 2,
                        type: "spring",
                      }}
                      className="my-col"
                    >
                      {choose_us_image3 && (
                        <img
                          className="img-fluid"
                          src={`${dataUrl}/assets/${choose_us_image3.file_name}`}
                          alt=""
                        />
                      )}
                    </motion.div>
                  </div>
                </div>
              </div>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{
                  delay: 0.6,
                  duration: 2,
                  type: "spring",
                }}
                className="col-lg-6 d-flex align-items-center"
              >
                <div className="choose-text">
                  <h3>
                    WHY <span className="heading-span">CHOOSE US</span>


                  </h3>
                {chooseUs?.content && <p>
                    {parse(chooseUs?.content)}
                  </p>}
                  <div className="btn-sec">
                    <Link className="btn-link" to="/pages/about-us">
                      READ MORE
                    </Link>
                  </div>
                </div>
              </motion.div>
            </div>
        </div>
      </div>
    </>
  );
};
export default ChooseUs;
