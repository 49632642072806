import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constant/colors";

const useStyles = makeStyles({
  formSec: {
   width: "100%",
   textAlign: "center",
   padding: '0 15px'
  },
  heading: {
    margin: '60px 0 40px',
  },
  form: {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    padding: '35px 30px',
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
  },
  inputFeild: {
    padding: "12px 0 !important",
  },
  submit: {
    width: '100%',
    border: 'none',
    marginTop: 10,
    background: COLORS.primary,
    color: '#fff',
    padding: '10px 20px',
    borderRadius: 5,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: COLORS.secondary,
    }
  },
  detailRow: {
    display: 'flex',
    paddingBottom: 10,
    borderBottom: '1px solid #e0e0e0',
    marginBottom: 10,
    alignItems: 'center',
  },
  col: {
    marginLeft: 15,
    textAlign: 'left',
  },
})

export default useStyles;