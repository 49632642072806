import React, { useEffect } from "react";
import CategoriesSec from "../CategoriesSec";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../actions/categories";
import useStyles from "./styles";
import { setMeta } from "../../actions/meta";
import { Link, useParams } from "react-router-dom";
import { COLORS } from "../../constant/colors";
import './index.css';

export default function ProductLayout(props) {
  const categories = useSelector((state) => state.categories.data);
  const meta = useSelector((state) => state.meta.data);
  const classes = useStyles();
  const { categoryId } = useParams();

  const mainCategories = categories.filter((record) => record.parent_id !== 0);
  const dispatch = useDispatch();

  const currentCategory = categories.find(
    (cat) => cat.id === parseInt(categoryId)
  );

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">
          <i className="fa fa-home" style={{ color: COLORS.primary }} />
        </Link>{" "}
        <i className="fa fa-angle-right" /> Categories  <i className="fa fa-angle-right" />  {currentCategory?.name}
      </>
    );
    dispatch(setMeta({ title: "Product", breadcrumb }));
  }, [currentCategory, dispatch]);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  let pageJsx = (
    <>
      {/* <div className={classes.innerBanner}>
      <div className="container">
        <div className="row">
          <div className="col">
            <ul className="breadcrumb_info">
              <li className={classes.breadcrumbValue}>{meta.breadcrumb}</li>
            </ul>
          </div>
        </div>
      </div>
      </div> */}

      <div className="inner-banner">
        <div className="container">
          <h2 className="heading">Products</h2>
          <ul className="breadcrumb_info">
              <li className={classes.breadcrumbValue}>{meta.breadcrumb}</li>
            </ul>
        </div>
      </div>

      <div className={`${props.parentClass} listing-sec`}>
        <div className="container">
          <div className={`row ${classes.row}`}>
            <CategoriesSec
              mainCategories={mainCategories}
              categoryId={props.categoryId}
            />
            {props.children}
          </div>
        </div>
      </div>
    </>
  );

  return <div>{pageJsx}</div>;
}
