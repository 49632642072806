import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loaderSec: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    marginTop: '100px',
    alignItems: 'center',
  },
  breadcrumbInfo: {
    paddingTop: 20,
  },
  loader: {
    width: 50,
    textAlign: "center",
    marginBottom: '10px',
  },
  innerSection: {
    padding: "10px 0px 50px",
  },
  notFoundSec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  }
});

export default useStyles;