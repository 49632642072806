import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "100px 0px 30px",
          textAlign: "center",
        }}
      >
        <div style={{ width: 400 }}>
          <img
            src="/images/not-found-page.jpg"
            alt="404"
            style={{ width: "100%", margin: "0 auto" }}
          />

          <div>
            <h5>Looks like you are lost</h5>
            <p>We can't find the page you're looking for</p>
          </div>
          <div className="btn-sec">
            <Link
              to="/"
              style={{ color: "#fff", position: "relative", zIndex: 100 }}
            >
              Go Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
