import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from "react-redux";

import CountUp from "react-countup";

const Expertise = () => {
  const [focus, setFocus] = React.useState(false);
  const settings = useSelector((state) => state.settings.data);

  const number_of_products_number = settings.find(
    (e) => e.key === "our_expertise_number_of_products_number"
  );

  const number_of_employees = settings.find(
    (e) => e.key === "our_expertise_number_of_employees"
  );

  const number_of_clients = settings.find(
    (e) => e.key === "our_expertise_number_of_clients"
  );

  const number_of_team = settings.find(
    (e) => e.key === "our_expertise_number_of_team"
  );

  return (
    <>
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            setFocus(true);
          }
        }}
      >
        <motion.div
          initial={{ opacity: 1, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.2,
            duration: 2,
            type: "spring",
          }}
          id="expertiseSec"
          className="expertise-sec"
        >
          <div className="container">
            <div className="expertise">
              <div className="expertise-text">
                <h3>
                  OUR <span className="heading-span">EXPERTISE</span>
                </h3>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-4 col-6 expertise-col">
                  <CountUp
                    startOnMount={true}
                    start={focus ? 0 : null}
                    duration={5}
                    end={number_of_products_number?.value}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div className="about-expertise">
                        <div className="img-icon">
                          <img
                            className="img-fluid"
                            src="/images/expertise-logo.jpg"
                            alt="expertise-logo"
                          />
                        </div>
                        <div className="img-text">
                          <div className="counter-div">
                            <span className="counter" ref={countUpRef} />
                            {!number_of_products_number?.comment?.includes(
                              "#"
                            ) ? (
                              <span className="counter-span">
                                {number_of_products_number?.comment}
                              </span>
                            ) : null}
                          </div>
                          <h6>NO. OF PRODUCTS</h6>
                        </div>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="col-lg-3 col-md-4 col-6 expertise-col">
                  <CountUp
                    startOnMount={true}
                    start={focus ? 0 : null}
                    duration={5}
                    end={number_of_employees?.value}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div className="about-expertise">
                        <div className="img-icon">
                          <img
                            className="img-fluid"
                            src="/images/expertise-logo1.jpg"
                            alt="expertise-logo"
                          />
                        </div>
                        <div className="img-text">
                          <div className="counter-div">
                            <span className="counter" ref={countUpRef} />
                            {!number_of_employees?.comment?.includes("#") ? (
                              <span className="counter-span">
                                {number_of_employees?.comment}
                              </span>
                            ) : null}
                          </div>
                          <h6>NO. OF EMPLOYEES</h6>
                        </div>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="col-lg-3 col-md-4 col-6 expertise-col">
                  <CountUp
                    startOnMount={true}
                    start={focus ? 0 : null}
                    duration={5}
                    end={number_of_clients?.value}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div className="about-expertise">
                        <div className="img-icon">
                          <img
                            className="img-fluid"
                            src="/images/expertise-logo2.jpg"
                            alt="expertise-logo"
                          />
                        </div>
                        <div className="img-text">
                          <div className="counter-div">
                            <span className="counter" ref={countUpRef} />
                            {!number_of_clients?.comment?.includes("#") ? (
                              <span className="counter-span">
                                {number_of_clients?.comment}
                              </span>
                            ) : null}
                          </div>
                          <h6>NO. OF CLIENTS</h6>
                        </div>
                      </div>
                    )}
                  </CountUp>
                </div>
                <div className="col-lg-3 col-md-4 col-6 expertise-col">
                  <CountUp
                    startOnMount={true}
                    start={focus ? 0 : null}
                    duration={5}
                    end={number_of_team?.value}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div className="about-expertise">
                        <div className="img-icon">
                          <img
                            className="img-fluid"
                            src="/images/expertise-logo3.jpg"
                            alt="expertise-logo"
                          />
                        </div>
                        <div className="img-text">
                          <div className="counter-div">
                            <span className="counter" ref={countUpRef} />
                            {!number_of_team?.comment?.includes("#") ? (
                              <span className="counter-span">
                                {number_of_team?.comment}
                              </span>
                            ) : null}
                          </div>
                          <h6>NO. OF TEAM</h6>
                        </div>
                      </div>
                    )}
                  </CountUp>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </VisibilitySensor>
    </>
  );
};
export default Expertise;
