import { createAction } from "redux-actions";
import axios from "axios";
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SUBMIT_REVIEW_SUCCESS,
  SUBMIT_REVIEW_FAILURE,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_SPECIAL_PRODUCTS_SUCCESS,
  GET_SPECIAL_PRODUCTS_FAILURE,
  GET_NEW_ARRIVALS_SUCCESS,
  GET_NEW_ARRIVALS_FAILURE,
  GET_BEST_SELLERS_SUCCESS,
  GET_BEST_SELLERS_FAILURE,
  GET_LABELED_PRODUCTS_SUCCESS,
  GET_LABELED_PRODUCTS_FAILURE,
} from "../constants/actionTypes";
import {
  getAllReviews,
  getItemList,
  getProduct,
  getSearchList,
  postReview,
} from "../api/items";
import { showSuccessSnackbar } from "./snackbar";
import { dataUrl } from "../helpers/common";

const getProductsSuccess = createAction(GET_PRODUCTS_SUCCESS);
const getProductsFailure = createAction(GET_PRODUCTS_FAILURE);

export function getProducts(queryParamsObj, perPage, page) {
  return (dispatch) =>
    getItemList("products", queryParamsObj, perPage, page)
      .then((json) =>
        dispatch(
          getProductsSuccess({
            ...json,
            productLabels: queryParamsObj.product_labels,
          })
        )
      )
      .catch((error) => dispatch(getProductsFailure(error)));
}

const getSpecialProductsSuccess = createAction(GET_SPECIAL_PRODUCTS_SUCCESS);
const getSpecialProductsFailure = createAction(GET_SPECIAL_PRODUCTS_FAILURE);

export function getSpecialProducts(label) {
  return (dispatch) =>
    getItemList(
      `products?ProductsSearch[product_labels]=${label}&sort=display_order`,
      100,
      1
    )
      .then((data) => {
        dispatch(getSpecialProductsSuccess({ label, ...data }));
      })
      .catch((error) => {
        dispatch(getSpecialProductsFailure(error));
      });
}


const getNewArrivalsSuccess = createAction(GET_NEW_ARRIVALS_SUCCESS);
const getNewArrivalsFailure = createAction(GET_NEW_ARRIVALS_FAILURE);

export function getNewArrivals(label) {
  return (dispatch) =>
    getItemList(
      `products?ProductsSearch[product_labels]=${label}&sort=display_order`,
      100,
      1
    )
      .then((data) => {
        dispatch(getNewArrivalsSuccess({ label, ...data }));
      })
      .catch((error) => {
        dispatch(getNewArrivalsFailure(error));
      });
}

const getBestSellersSuccess = createAction(GET_BEST_SELLERS_SUCCESS);
const getBestSellersFailure = createAction(GET_BEST_SELLERS_FAILURE);

export function getBestSellers(label) {
  return (dispatch) =>
    getItemList(
      `products?ProductsSearch[product_labels]=${label}&sort=display_order`,
      100,
      1
    )
      .then((data) => {
        dispatch(getBestSellersSuccess({ label, ...data }));
      })
      .catch((error) => {
        dispatch(getBestSellersFailure(error));
      });
}

const getSingleProductSuccess = createAction(GET_SINGLE_PRODUCT_SUCCESS);
const getSingleProductFailure = createAction(GET_SINGLE_PRODUCT_FAILURE);

export function getSingleProduct(productId) {
  return (dispatch) =>
    getProduct(productId)
      .then((json) =>
        dispatch(
          getSingleProductSuccess({
            ...json,
          })
        )
      )
      .catch((error) => dispatch(getSingleProductFailure(error)));
}

export const getSinglProduct = async (id) => {
  return axios.get(`${dataUrl}/products/` + id);
};

const submitReviewSuccess = createAction(SUBMIT_REVIEW_SUCCESS);
const submitReviewFailure = createAction(SUBMIT_REVIEW_FAILURE);

export function submitNewReview(params, customer) {
  return (dispatch) =>
    postReview(params)
      .then((data) => {
        dispatch(showSuccessSnackbar("Your review has been submitted"));
        dispatch(submitReviewSuccess({ ...params, customer }));
        return true;
      })
      .catch((error) => {
        dispatch(submitReviewFailure(error));
        return false;
      });
}

const getReviewsSuccess = createAction(GET_REVIEWS_SUCCESS);
const getReviewsFailure = createAction(GET_REVIEWS_FAILURE);

export function getReviews(productId) {
  return (dispatch) =>
    getAllReviews(productId)
      .then((data) => dispatch(getReviewsSuccess(data)))
      .catch((error) => dispatch(getReviewsFailure(error)));
}


export function searchProducts(keywords) {
  return getSearchList('?ProductsSearch[keyword]=' + keywords, 100, 1);
}




const getLabeledProductsSuccess = createAction(GET_LABELED_PRODUCTS_SUCCESS);
const getLabeledProductsFailure = createAction(GET_LABELED_PRODUCTS_FAILURE);

export function getLabeledProducts(label, perPage, page) {
  return dispatch => getItemList('products', `ProductsSearch[product_labels]=${label}`, perPage, page)
    .then((json) => dispatch(getLabeledProductsSuccess({
      ...json,
      label,
    })))
    .catch(error => dispatch(getLabeledProductsFailure(error)))
}