import React, { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updateProfile } from "../../actions/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { authKey_local_storage_key } from "../../helpers/common";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const customer = useSelector((state) => state.customers.profile);
  const token = localStorage.getItem(authKey_local_storage_key);
  const classes = useStyles();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { name, additional_mobile_number, mobile_number, pin_code, email } =
    useSelector((state) => state.customers.profile);

  const [profile, setProfile] = useState({
    name: name || "",
    email: email || "",
    pin_code: pin_code || "",
    mobile_number: mobile_number || "",
    additional_mobile_number: additional_mobile_number || "",
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (!profile.name) {
      setErrors({ ...errors, name: "Name is required" });
      return;
    }

    if (!profile.mobile_number) {
      setErrors({ ...errors, mobile_number: "Mobile number is required" });
      return;
    }

    setLoading(true);

    return dispatch(updateProfile({ ...profile, id: customer.id })).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          dispatch(showSuccessSnackbar("Profile Updated successfully !"));
          const customer = res.data;
          localStorage.setItem("userInfo", JSON.stringify({ customer, token }));
          navigate("/profile");
        } else
          dispatch(showErrorSnackbar("Sorry! Profile cannot be updated !"));
      }
    );
  };

  const onValueChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  return (
    <section>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={8}
      >
        <h2 className={classes.heading}>My Profile</h2>
        <div className={classes.formSec}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Box className={classes.inputRow}>
              <Grid container alignItems="center">
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={profile.name}
                  variant="standard"
                  name="name"
                  onChange={onValueChange}
                  label="Name *"
                  error={errors.name ? true : false}
                  helperText={errors.name}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems="center">
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={profile.mobile_number}
                  variant="standard"
                  type="number"
                  name="mobile_number"
                  label="Mobile Number *"
                  helperText={errors.mobile_number}
                  error={errors.mobile_number ? true : false}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems="center">
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  variant="standard"
                  type="email"
                  name="email"
                  label="Email"
                  value={profile.email}
                  inputProps={{ minLength: 6 }}
                  onChange={onValueChange}
                />
              </Grid>
            </Box>

            <Box className={classes.inputRow}>
              <Grid container alignItems="center">
                <TextField
                  className={classes.inputFeild}
                  fullWidth
                  value={profile.additional_mobile_number}
                  variant="standard"
                  type="number"
                  name="additional_mobile_number"
                  label="Additional Mobile Number"
                />
              </Grid>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              className="login-btn"
            >
              <button className={classes.submit} type="submit">
                {loading ? "Please Wait..." : "Update Profile"}
              </button>
            </Box>
          </form>
        </div>
      </Box>
    </section>
  );
}
