import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { dataUrl } from "../../../helpers/common";
import parse from 'html-react-parser'
const ReadMore = () => {
  const transition = {
    duration: 2,
    delay: 0.2,
    type: "spring",
    stiffness: 100,
  };

  const readMoreBanners = useSelector(
    (state) => state.banners["Read More Banners"]
  );
  const contents = useSelector((state) => state.contents.data);
  const readMore = contents.find(e => e.name === 'read_more')

  return (
    <>
      <div className="read-more-sec my-sec">
        <div className="container">
          <div className="row flex">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={transition}
              className="col-lg-4"
            >
              <div className="r-m-text">
                {readMore?.name && <p>{parse(readMore.content)}</p>}
                <div className="btn-sec">
                  <Link className="btn-link" to="/pages/about-us">
                    READ MORE
                  </Link>
                </div>
              </div>
            </motion.div>
            <div className="col-lg-8">
              <div className="row readmore-row">
                <div className="col-sm-4 readmore-img">
                  {readMoreBanners && readMoreBanners.length > 0 ? (
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ ...transition, delay: 0.3 }}
                      className="my-col"
                    >
                      <img
                        className="img-fluid anim"
                        src={`${dataUrl}/banners/${readMoreBanners[0].image}`}
                        alt=""
                      />
                    </motion.div>
                  ) : null}
                  <div className="readmore-left-frame">
                    <img
                      className="img-fluid"
                      src="images/readmore-left-frame.png"
                      alt=""
                    />
                  </div>
                </div>
                {readMoreBanners && readMoreBanners.length > 1 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ...transition, delay: 0.5 }}
                    className="col-sm-4 readmore-img"
                  >
                    <div className="my-col">
                      <img
                        className="img-fluid anim"
                        src={`${dataUrl}/banners/${readMoreBanners[1].image}`}
                        alt=""
                      />
                    </div>
                  </motion.div>
                ) : null}
                {readMoreBanners && readMoreBanners.length > 2 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ...transition, delay: 0.8 }}
                    className="col-sm-4 readmore-img"
                  >
                    <div className="my-col">
                      <img
                        className="img-fluid anim"
                        src={`${dataUrl}/banners/${readMoreBanners[2].image}`}
                        alt=""
                      />
                    </div>
                    <div className="readmore-right-frame">
                      <img
                        className="img-fluid"
                        src="images/readmore-right-frame.png"
                        alt=""
                      />
                    </div>
                  </motion.div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReadMore;
