import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  innerBanner: {
    marginBottom: '50px',
    background: 'url(/images/inner_banner2.jpg) no-repeat center',
    backgroundSize: 'cover',
    width: '100%',
    minHeight: 240,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minHeight: 'auto',
      padding: '10% 0',
    }
  },
  content: {
    padding: '50px 0px',
  },
  row: {
    marginBottom: 50,
  },
  breadcrumbValue: {
    textTransform: 'capitalize',
  }
})


export default useStyles;