import React from "react";
import { Box } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { hideSnackbarMessage } from "../../../actions/snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SimpleSnackbar({ message, open, severity, action }) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(hideSnackbarMessage());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={!action && handleClose}
        severity={severity}
        style={{ fontFamily: "Raleway" }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {message}
          <Box ml={3}>{action}</Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}
