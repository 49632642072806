import React from "react";
import { Link } from "react-router-dom";
import { getCategoriesByParentName } from "../../../helpers/common";
import CategoryBox from "./CategoryBox";
import ProductBox from "./ProductBox";

const DropdownMenuWeb = ({
  topMenus,
  categories,
  labeledProducts,
  setSubMenuVisible,
  openModal,
}) => {
  return (
    <ul className="menu-main">
      {topMenus.map((menu) => (
        <li
          key={menu.id}
          onClick={() => setSubMenuVisible(true)}
          className="menu-item-has-children"
        >
          <Link
            className="nav-link"
            to={
              menu?.url?.includes("#labels/") || menu?.url?.includes("#")
                ? "#"
                : menu.url
            }
          >
            {menu.name}
          </Link>
          {getCategoriesByParentName(categories, menu.name)?.length > 0 ||
          labeledProducts[menu.name]?.length > 0 ? (
            <div className="sub-menu mega-menu mega-menu-column-4">
              {menu.url.includes("#labels/")
                ? labeledProducts[menu.name]?.map((product, index) => (
                    <ProductBox
                      openModal={openModal}
                      key={"product-" + index}
                      product={product}
                    />
                  ))
                : getCategoriesByParentName(categories, menu.name)?.map(
                    (category, index) => (
                      <CategoryBox
                        key={"category" + index}
                        category={category}
                      />
                    )
                  )}
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenuWeb;
