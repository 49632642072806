import React, { useEffect } from "react";
import "./index.css";

export default function AppLoader() {
  useEffect(() => {
    setInterval(() => {
      const loader_sec = document.getElementById("loader-sec");
      loader_sec.classList.add("hide");
    }, 3000);
  }, []);

  return (
    <div id="loader-sec" className={`main-loader-sec `}>
      <img className="main-loader" src="/images/loader.gif" alt="loader" />
    </div>
  );
}
