/* eslint-disable linebreak-style */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import "./assets/css/bootstrap.css";
import "./assets/css/font-awesome.css";
import "./assets/css/style.css";
import "./index.css";
import "./assets/css/responsive.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import HomePage from "./components/HomePage";
import {
  authProtectedRoutes,
  commonRoutes,
  publicRoutes,
} from "./store/routes";
import NotFoundPage from "./components/NotFoundPage";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<App />} exact>
            <Route index element={<HomePage />} />

            {commonRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}

            {publicRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}

            {authProtectedRoutes.map((route) => (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={route.path}
                  />
                ))}



            {/* TODO: fix authProtectedRoutes */}
            {/* {authProtectedRoutes.map((route) => (
              <Route
                exact
                path={route.path}
                element={
                  <PrivateRoute>
                    {route.element}
                  </PrivateRoute>
                }
              />
            ))} */}

            <Route path="*" element={<NotFoundPage />} />
          </Route>
          {/* <Route element={NotFoundPage} /> */}
          {/* <Route path="" element={<Navigate to="/" />} /> */}
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
