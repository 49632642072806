import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constant/colors';

const useStyles = makeStyles(theme => ({
  checkoutSec: {
    padding: '50px 0',
  },
  heading: {
    textAlign: "center",
    marginBottom: 60,
  },
  detailBox: {
    padding: '30px',
    background: '#f8f9fa',
    marginBottom: 40,
    width: '90%',
  },
  li: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
    '& span': {
      fontWeight: 'bold',
    }
  },
  btnSec: {
    marginTop: 25,
  },
  btnLink: {
    padding: "8px 30px",
    color: "#fff",
    display: "inline-block",
    borderRadius: 5,
    background: COLORS.primary,
    transition: 'all 0.3s ease-in-out',
    "&:hover": {
      color: "#fff",
      background: COLORS.secondary,
    },
  },
  
}));

export default useStyles;
