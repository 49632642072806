import React from "react";
import { productImageUrl } from "../../helpers/common";
import { Link } from "react-router-dom";
import "../../index.css";
import useStyles from "./styles";
import "./index.css";
import { motion } from "framer-motion";

export default function ProductBox({
  product,
  index,
  openModal,
  isSimilarProducts = false,
}) {

  const bootstrapClasses = isSimilarProducts
    ? "col-lg-3 col-md-3 col-sm-6"
    : "col-lg-4 col-md-6 col-sm-6";

  const classes = useStyles();
  return (
    <motion.div
      onClick={() => openModal(product)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 + index * 0.3 }}
      key={product.id}
      className={`${bootstrapClasses} ${classes.myCol}`}
    >
      <Link to={"#"} className="product-box">
        <div className="productImgBlock">
          <img
            className="product-img img-fluid"
            src={productImageUrl(
              product.main_image,
              "products",
              product.id,
              "406x406-"
            )}
            alt="product-img"
          />
        </div>
        <div className="content">
          <h3>{product.name}</h3>
          {/* <div className="price">
            <del className={classes.del}>Rs.{product.mrp}</del>
            <span className={classes.price}> Rs.{product.selling_price}</span>
          </div> */}
        </div>
      </Link>
    </motion.div>
  );
}
