
export const COLORS = {
  primary: "#9a2551",
  secondary: "#ffce2a",
  danger: "#ed1c24",
  darkGrey1: "#333333",
  darkGrey2: "#444444",
  lightGrey: "#cbcbcb",
  lightGrey1: "#777777",
  lightGrey2: "#696969",
  white: "#ffffff",
  black: "#000000",
  success: "#00b300",
};