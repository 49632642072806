import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/pages";
import { motion } from "framer-motion";
import "./index.css";

import NotFound from "../NotFoundPage";

const CmsPage = () => {
  const params = useParams();
  const [details, setDetails] = useState({ content: "", title: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.page) {
      setLoading(true);
      getPage(params.page)
        .then((json) => {
          setDetails(json.data?.[0]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    window.scrollTo(0, 0);
  }, [params.page]);

  let content = details ? (
    <div dangerouslySetInnerHTML={{ __html: details.content }} />
  ) : (
    <NotFound />
  );
  return (
    <>
      {details ? (
        <div className="inner-banner">
          <div className="container">
            <h2 className="heading">{details?.title}</h2>
          </div>
        </div>
      ) : null}
      {loading ? (
        <div className="loader-container">
          <div>
            <img src="/images/loader.gif" alt="loader" />
            <p>loading...</p>
          </div>
        </div>
      ) : (
        <div className="cms-content">
          <div className="list_sec">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="container"
            >
              {content}
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
};

export default CmsPage;
