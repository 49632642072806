import React from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { dataUrl, getContentByCategoryName } from "../../../helpers/common";
import striptags from "striptags";
const Banner = () => {
  const contents = useSelector((state) => state.contents.data);
  const banners = useSelector(
    (state) => state.banners["Business Homepage Banners"]
  );

  const bannerContent = getContentByCategoryName(
    contents,
    "banner_content"
  );


  const transition = {
    duration: 2,
    delay: 0.2,
    type: "spring",
    stiffness: 200,
  };
  return (
    <>
      <div className="banner-sec">
        <div className="container">
          <div className="row align-items-center">
            <motion.div
              className="col-lg-6 banner-left-col"
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={transition}
            >
              <div className="welcome">
                <p>{bannerContent?.value1}</p>
              </div>
              <h1>WELCOME</h1>
              <p className="desc">
                {striptags(bannerContent?.content)}
              </p>
              <div className="btn-sec">
                <Link className="btn-link" to="/pages/about-us">
                  Read More
                </Link>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-6 banner-right-col"
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ ...transition, delay: 0.5 }}
            >
              <div className="banner-image">
                {banners && banners.length > 0 ? (
                  <img
                    className="img-fluid"
                    src={`${dataUrl}/banners/${banners[0]?.image}`}
                    alt="banner-img"
                  />
                ) : null}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
