import React from "react";
import "./index.css";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

const AboutMet = () => {
  const transition = {
    duration: 2,
    type: "spring",
    stiffness: 300,
    delay: 0.3,
  };

  const contents = useSelector((state) => state.contents.data);

  const description = contents.find(
    (e) => e.name === "About Metcraft Chitra Description"
  );
  const quality = contents.find((e) => e.name === "QUALITY");
  const relationship = contents.find((e) => e.name === "RELATIONSHIP");
  const services = contents.find((e) => e.name === "SERVICES");

  return (
    <>
      <div className="about-sec my-sec">
        <div className="container">
          <h3>
            ABOUT <span className="heading-span">METCRAFT CHITRA</span>
          </h3>
          {description?.content && (
            <p className="description">{parse(description?.content)}</p>
          )}
          <div className="row">
            <motion.div
              className="col-lg-4 col-md-6"
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ...transition, delay: 0.4 }}
            >
              <div className="about-box">
                <div className="content">
                  <h4>{quality?.name}</h4>
                  {quality?.content && <p>{parse(quality?.content)}</p>}
                </div>
                <div>
                  <img
                    className="img-fluid"
                    src="/images/about-logo-slide1.png"
                    alt="logo-slide1"
                  />
                </div>
              </div>
            </motion.div>
            <motion.div
              className="col-lg-4 col-md-6"
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ...transition, delay: 0.6 }}
            >
              <div className="about-box">
                <div className="content">
                  <h4>{relationship?.name}</h4>
                  {relationship?.content && (
                    <p>{parse(relationship?.content)}</p>
                  )}
                </div>
                <div className="img-div">
                  <img
                    className="img-fluid"
                    src="/images/about-logo-slide2.png"
                    alt="logo-slide1"
                  />
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ ...transition, delay: 0.8 }}
              className="col-lg-4 col-md-6"
            >
              <div className="about-box">
                <div className="content">
                  <h4>{services?.name}</h4>
                  {services?.content && <p>{parse(services?.content)}</p>}
                </div>
                <div>
                  <img
                    className="img-fluid"
                    src="/images/about-logo-slide3.png"
                    alt="logo-slide1"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutMet;
