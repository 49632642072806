import ajax from './ajax';

export function contactUs(params) {
  return ajax('/contacts', { method: 'POST', data: params });
}


export function subscribeToNewsLetter(params) {
  return ajax('/newsletters', { method: 'POST', data: params });
}

