import { createAction } from "redux-actions";

import { SET_META_DATA, SET_IS_HOMEPAGE } from "../constants/actionTypes";

const setMetaAction = createAction(SET_META_DATA);

export function setMeta(meta) {
  return (dispatch) => dispatch(setMetaAction({ meta }));
}

const seIstHomepage = createAction(SET_IS_HOMEPAGE);
export function setHomePage(bool) {
  return (dispatch) => dispatch(seIstHomepage(bool));
}
