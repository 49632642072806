import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { postContactUs } from "../../actions/contactUs";
import { getContents } from "../../actions/contents";
import { motion } from "framer-motion";
import "./index.css";
import { showErrorSnackbar } from "../../actions/snackbar";

export default function ConactUs(props) {
  const profile = useSelector((state) => state.customers.profile);
  const settings = useSelector((state) => state.settings.data);
  const [email, setEmail] = useState(profile.email ?? "");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [feedback, setFeedback] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const companyAddress = settings.find(
    (item) => item.key === "company_address"
  );
  const companyPhoneNumber = settings.find(
    (item) => item.key === "company_contact_number"
  );
  const companyEmail = settings.find(
    (item) => item.key === "company_email_address"
  );

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      email === "" ||
      subject === "" ||
      feedback === "" ||
      name === "" ||
      phone === ""
    ) {
      dispatch(showErrorSnackbar("Please fill all the required * fields"));
      return;
    }

    setLoading(true);
    dispatch(
      postContactUs({
        name: profile.name ?? name,
        email: profile.email ?? email,
        contact_number: profile.mobile_number ?? phone,
        subject,
        captcha_value: "N/A",
        // TODO: Add captcha value
        message: feedback,
      })
    ).then((json) => {
      setLoading(false);
      setFeedback("");
      setSubject("");
      setName("");
      setPhone("");
      setEmail("");
    });
    dispatch(getContents("homepage"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="contact-us">
      <div className="inner-banner">
        <div className="container">
          <h2 className="heading">Contact Us</h2>
        </div>
      </div>

      <div className="contact-sec">
        <div className="container">
          <div className="row section-row">
            <div className={`col-lg-6 col-md-6 leftCol`}>
              <div className="w-100">
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="my-row"
                >
                  <div className="col-2">
                    <i className={`fa fa-map-marker icon`}></i>
                  </div>
                  <div className="col-10">
                    <h3 className="contact-title">Head Office</h3>
                    <p>{companyAddress?.value}</p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  className="my-row"
                >
                  <div className="col-2">
                    <i className={`fa fa-phone icon`}></i>
                  </div>
                  <div className="col-10">
                    <h3 className="contact-title">Customer Care</h3>
                    <a
                      className="link"
                      href={`tel:${companyPhoneNumber?.value}`}
                    >
                      {companyPhoneNumber?.value}
                    </a>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 }}
                  className="my-row"
                >
                  <div className="col-2">
                    <i className={`fa fa-envelope-o icon`}></i>
                  </div>
                  <div className="col-10">
                    <h3 className="contact-title">Email</h3>
                    <a className="link" href={`mailto:${companyEmail?.value}`}>
                      {companyEmail?.value}
                    </a>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                  className="my-row"
                >
                  <div className="col-2">
                    <i className={`fa fa-globe icon`}></i>
                  </div>
                  <div className="col-10">
                    <h3 className="contact-title">Website</h3>
                    <Link className="link" to="/">
                      www.metcraftchitra.com
                    </Link>
                    <Link className="link" to="/">
                      www.shop.metcraftchitra.com (for Online Shopping)
                    </Link>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="form"
              >
                <h3 className="title">Send Message</h3>
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12 input-col">
                      <input
                        className="input"
                        type="name"
                        name="name"
                        value={name}
                        placeholder="Your Name *"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        className="input"
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Your Email *"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12 input-col">
                      <input
                        className="input"
                        type="number"
                        name="phone"
                        value={phone}
                        placeholder="Your Number *"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <input
                        className="input"
                        type="name"
                        name="subject"
                        value={subject}
                        placeholder="Subject *"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="input textarea"
                        type="text"
                        name="feedback"
                        value={feedback}
                        placeholder="Message *"
                        onChange={(e) => setFeedback(e.target.value)}
                      />
                    </div>
                    {loading ? (
                      <button className="submit" type="button">
                        <span>Please Wait..</span>
                      </button>
                    ) : (
                      <button className="submit" type="submit">
                        <span>Submit</span>
                      </button>
                    )}
                  </div>
                </form>
              </motion.div>
            </div>
          </div>
          <div className="map-sec">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56423.24421060039!2d78.06962700000001!3d27.888076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x90c3582947e6d2f7!2sMETCRAFT%20BUILDWARE%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1649852391589!5m2!1sen!2sin"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              frameborder="0"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
