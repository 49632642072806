import CmsPage from '../../components/CmsPage';
import ProductListing from '../../components/ProductListing';
import ContactUs from '../../components/ContactUs';
import Shipping from '../../components/Shipping';
import Checkout from '../../components/Checkout';
import SearchResult from '../../components/SearchResult';
import Profile from '../../components/Profile';
import Login from '../../components/Login';
import UpdateProfile from '../../components/Profile/UpdateProfile';


const commonRoutes = [
  {
    path: "/products/:categoryId/:currentPage/:categoryName",
    element: <ProductListing />,
  },
  { path: "/products/:categoryId/:currentPage", element: <ProductListing /> },
  { path: "/products", element: <ProductListing /> },
  {
    path: "/product/:productId/:currentPage/:productName",
    element: <ProductListing />,
  },
  // {
  //   path: "/product/:productId/:productName",
  //   element: <SingleProduct />,
  // },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/pages/:page", element: <CmsPage /> },
  { path: "/search/:keyword", element: <SearchResult /> },
  { path: "/login", element: <Login /> },
];

const authProtectedRoutes = [
  { path: "/profile", element: <Profile /> },
  { path: "/update-profile", element: <UpdateProfile /> },
  { path: "/shipping", element: <Shipping/> },
  { path: "/checkout/:orderId", element: <Checkout/> },
];

const publicRoutes = [
  // { path: '/signup', component: SignUp },
  // { path: '/forget-password', component: ForgetPassword }
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
