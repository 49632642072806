import React from "react";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { getCategoryLink, getCategoryByParentId } from "../../helpers/common";
import "./index.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

const FirstLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);

  if (category.total_sub_categories === 0) {
    return (
      <MenuItem className="firstLevel">
        {category.name}
        <Link to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <SecondLevelMenu
            handleClick={handleClick}
            key={"SecondLevelMenu-" + category.id}
            categories={categories}
            category={category}
          />
        ))}
      </SubMenu>
    );
  }
};

const SecondLevelMenu = ({ categories, category, handleClick }) => {
  let subCats = getCategoryByParentId(categories, category.id);

  if (category.total_sub_categories === 0) {
    return (
      <MenuItem>
        {category.name}
        <Link onClick={handleClick} to={getCategoryLink(category)} />
      </MenuItem>
    );
  } else {
    return (
      <SubMenu title={category.name}>
        {subCats.map((category) => (
          <ThirdLevelMenu
            key={"ThirdLevelMenu-" + category.id}
            category={category}
            handleClick={handleClick}
          />
        ))}
      </SubMenu>
    );
  }
};

const ThirdLevelMenu = ({ category, handleClick }) => {
  return (
    <MenuItem>
      {category.name}
      <Link onClick={handleClick} to={getCategoryLink(category)} />
    </MenuItem>
  );
};

export default function CategoriesSec() {
  const categories = useSelector((state) => state.categories.data);

  // where parent is Main.
  const level1Categories = getCategoryByParentId(categories, 1);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 categories-list">
      <div className="web-category-sec">
        <h4 className="title">Categories</h4>
        <ProSidebar>
          <Menu>
            {level1Categories.map((category, index) => (
              <motion.div
                key={"level1Categories-" + category.id}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 + index * 0.2 }}
              >
                <FirstLevelMenu
                  handleClick={handleClick}
                  categories={categories}
                  category={category}
                />
              </motion.div>
            ))}
          </Menu>
        </ProSidebar>
      </div>

      
      <div className="mobile-category-sec">
        <div className="all-category-btn" onClick={handleClick}>
          <div className="d-flex align-items-center">
            <i class="fa fa-list" aria-hidden="true"></i>
            <h6>{open ? "Hide categories" : "See All Categories"}</h6>
          </div>
        </div>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <ProSidebar>
            <Menu>
              {level1Categories.map((category, index) => (
                <motion.div
                  key={"level1Categories-" + category.id}
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 + index * 0.2 }}
                >
                  <FirstLevelMenu
                    handleClick={handleClick}
                    categories={categories}
                    category={category}
                  />
                </motion.div>
              ))}
            </Menu>
          </ProSidebar>
        </Collapse>
      </div>
    </div>
  );
}
