import { SET_META_DATA, SET_IS_HOMEPAGE } from '../constants/actionTypes';

const defaultState = {
    data: {
        title: '',
        breadcrumb: '',
    },
    isHome: false,
};

export default function meta(state = defaultState, action) {
    switch (action.type) {
        case SET_META_DATA:
            return { ...state, data: action.payload.meta };
        case SET_IS_HOMEPAGE:
            return {...state, isHome: action.payload ?? false}
        default:
            return state;
    }
}
