import React from "react";
import {
  convertToSlug,
  getCategoriesByParentName,
  getMenusByCategory,
  imageUrl,
} from "../../../helpers/common";
import { useSelector } from "react-redux";
import "./index.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function SideBar({
  sideBarRef,
  openSideBar,
  setOpenSideBar,
  openModal,
}) {
  const menus = useSelector((state) => state.menus.menus);
  const topMenus = getMenusByCategory(menus, "top-menu-dropdown") ?? [];
  const labeledProducts =
    useSelector((state) => state.products.labeledProducts) ?? {};
  const categories = useSelector((state) => state.categories.data) ?? [];
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [currOpenMenu, setCurrOpenMenu] = useState({});
  const navigate = useNavigate();
  const productClick = (item) => {
    openModal(item);
    setOpenSideBar(!openSideBar);
    setOpenSubMenu(false);
    setCurrOpenMenu({});
  };

  // TODO: fix submenu click issue jab ham kisi sub menu pe click karte hai to last menu bhi open ho jata hai 


  const categoryClick = (item) => {
    navigate(`/products/${item.id}/1/${convertToSlug(item.name)}`);
    setOpenSubMenu(false);
    setCurrOpenMenu({});
    setOpenSideBar(!openSideBar);
  };

  const openCurrSubMenu = (menu) => {
    setCurrOpenMenu(menu);
    setOpenSubMenu(true);
  };
  return (
    <div
      className={`sideBarMenuFull ${
        openSideBar ? "sideBarMenuFullActive" : ""
      }`}
    >
      <div
        className={`sideBarMenu ${openSideBar ? "sideBarMenuActive" : ""}`}
        ref={sideBarRef}
      >
        <div className="sidebar-header">
          {openSubMenu ? (
            <div
              onClick={() => setOpenSubMenu(false)}
              className="sidebar-header-left"
            >
              <i className="fa fa-angle-left" />
            </div>
          ) : null}

          <div className="sidebar-header-center">
            {openSubMenu ? (
              <p className="sidebar-menu-title">{currOpenMenu?.name}</p>
            ) : null}
          </div>
          <div className="sidebar-header-right">
            <button
              onClick={() => setOpenSideBar(!openSideBar)}
              className={`navbar-toggler `}
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>

        <div className="menu-items">
          <ul>
            {topMenus.map((menu, index) => (
              <li key={menu.id} onClick={() => openCurrSubMenu(menu)}>
                <p>{menu.name}</p>
                <i className="fa fa-angle-right" />

                {currOpenMenu ? (
                  <div className={`sub-menu ${openSubMenu ? "active" : ""}`}>
                    {currOpenMenu?.url?.includes("#labels/")
                      ? labeledProducts[currOpenMenu.name]?.map((item) => {
                          return (
                            <div
                              onClick={() => productClick(item)}
                              key={item.id}
                              className="sub-menu-item"
                            >
                              <img
                                src={imageUrl(
                                  item.main_image,
                                  `products/${item.id}`
                                )}
                                alt="product-img"
                              />
                              <div className="texts">
                                <h5>{item.name}</h5>
                                <h6>Rs.500</h6>
                              </div>
                            </div>
                          );
                        })
                      : getCategoriesByParentName(
                          categories,
                          currOpenMenu.name
                        )?.map((category) => (
                          <div
                            onClick={() => categoryClick(category)}
                            key={category.id}
                            className="sub-menu-item"
                          >
                            <img
                              src={imageUrl(category.image, "categories")}
                              alt="category-img"
                            />
                            <div className="texts">
                              <h5>{category.name}</h5>
                            </div>
                          </div>
                        ))}
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
