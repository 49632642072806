import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { callOrdersCheckoutApi } from "../../actions/orders";
import { showErrorSnackbar } from "../../actions/snackbar";
import ShippingProPreview from "./ShippingProPreview";
import ShippingDetailsForm from "./ShippingDetailsForm";
import OrderSummary from "../OrderSummary";
import { getSettingByKey } from "../../helpers/common";
import { useNavigate } from "react-router-dom";

const Shipping = () => {
  const classes = useStyles();
  const token = localStorage.getItem("avon_authToken");
  const cartData = useSelector((state) => state.cart.data);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const delivery_preferred_date_time = localStorage.getItem('delivery_preferred_date_time');
  console.log('delivery_preferred_date_time', delivery_preferred_date_time);
  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    mobile_number: "",
    pin_code: "",
    additional_mobile_number: "",
  });
  const [remarks, setRemarks] = useState("");
  const [errors, setErrors] = useState({});
  const settings = useSelector((state) => state.settings.data);
  const allowedPincodes = getSettingByKey(settings, "allowed_pincodes");
  const dispatch = useDispatch();

  const totalQuantity = () => {
    return cartData?.reduce((acc, item) => item.qty + acc, 0);
  };

  const totalPrice = useCallback(() => {
    return cartData?.reduce(
      (acc, item) => item.selling_price * item.qty + acc,
      0
    );
  }, [cartData]);

  const checkout = useCallback(
    (customer) => {
      const items = cartData.map((d) => {
        return {
          item_id: d.id,
          quantity: d.qty,
        };
      });

      let coupon_code = customer.coupon_code || "";
      let customer_remarks = customer.customer_remarks || "";

      setLoader(true);

      delete customer.coupon_code;

      callOrdersCheckoutApi(dispatch, token, {
        customer,
        items,
        customer_remarks,
        coupon_code,
        delivery_preferred_date_time: delivery_preferred_date_time || "",
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            navigate(`/checkout/${response.data.order.order_id}`, {
              state: { order: response.data },
            });
          } else {
            dispatch(showErrorSnackbar('Something went wrong. Please try again'));
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            dispatch(showErrorSnackbar(error.response.data.message));
          } else {
            dispatch(showErrorSnackbar('Something went wrong. Please try again'));
          }
        })
        .finally(() => {
          setLoader(false);
        });
    },
    [cartData, dispatch, token, delivery_preferred_date_time, navigate]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setErrors({});

      if (!customer.name || !customer.name.trim().length) {
        setErrors((currErr) => {
          return {
            ...currErr,
            name: true,
          };
        });
        return false;
      }

      if (!customer.address || !customer.address.trim()) {
        setErrors((currErr) => {
          return {
            ...currErr,
            address: true,
          };
        });
        return false;
      }

      if (!customer.pin_code) {
        setErrors((currErr) => {
          return {
            ...currErr,
            pin_code: true,
          };
        });
        return false;
      }

      if (customer.pin_code) {
        const allowedPincodesArr = allowedPincodes
          ? allowedPincodes.value.split("\n")
          : [];

        const allowedPincodesArrTrimmed = allowedPincodesArr.map((e) =>
          e.trim()
        );

        if (!allowedPincodesArrTrimmed.includes(customer.pin_code)) {
          setErrors((currErr) => {
            return {
              ...currErr,
              pin_code: true,
            };
          });
          return false;
        }
      }

      if (
        customer.additional_mobile_number.trim().length &&
        customer.additional_mobile_number.trim().length < 10
      ) {
        setErrors((currErr) => {
          return {
            ...currErr,
            additional_mobile_number: true,
          };
        });
        return false;
      }

      const data = {
        ...customer,
        zip_code: customer.pin_code,
        coupon_code: customer.coupon_code,
        customer_remarks: customer.customer_remarks,
      };

      delete data.pincode;
      checkout(data);
    },
    [checkout, customer, allowedPincodes]
  );

  return (
    <section>
      <div className={classes.shippingSec}>
        <div className="container">
          <h2 className={classes.heading}>Shipping Details</h2>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-8">
                <Box flex={1.5} className="shipping-Form">
                  <ShippingDetailsForm
                    handleSubmit={handleSubmit}
                    customer={customer}
                    errors={errors}
                    remarks={remarks}
                    classes={classes}
                    loader={loader}
                    setRemarks={setRemarks}
                    setCustomer={setCustomer}
                  />
                </Box>
              </div>
              <div className="col-md-4">
                <div className="row flex-column">
                  <ShippingProPreview taxCharge="*Calculated at Next Step" />
                  <div className="mt-4">
                    <OrderSummary
                      loading={loader}
                      totalQuantity={totalQuantity}
                      totalPrice={totalPrice}
                      redirect={handleSubmit}
                      shippingCharges="(calculate at next step)"
                      buttonText="Checkout"
                      order={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Shipping;
