import React from "react";
import { Box, Divider} from '@mui/material';
import { useSelector } from 'react-redux';
import { productImageUrl } from '../../helpers/common';

export default function ShippingProPreview(props) {
const cartData = useSelector(state => state.cart.data);

  return (
    <Box flex={1} className="pro-preview">
      <Box flex={1} color="#666" fontSize={16} fontWeight={500} pb={2}>
        Product Details - {cartData.length} item(s)
      </Box>

      <Box mt={-3}>
          {cartData.map((product) => (
            <Box
              key={product.id}
              my={3}
              p={1}
              display="flex"
              fullwidth="true"
              alignItems="center"
            >
              <Box width={60} height={60}>
                <img
                  src={productImageUrl(
                    product.main_image,
                    "products",
                    product.id
                  )}
                  alt="cart-item"
                />
              </Box>

              <Box flex={1} ml={2}>
                <Box fontSize={16} fontWeight={600}>
                  {product.name}
                </Box>
                <Box>Qty: {product.qty}</Box>
              </Box>

              <Box fontSize={18} mx={3} color="#666">
                &#x20b9;{product.selling_price * product.qty}
              </Box>
            </Box>
          ))}
      </Box>
      <Divider fullWidth />


    </Box>
  );
}
