import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  myCol: {
    padding: '15px !important',
    textAlign: "center",
    margin: '0 auto',
    transition: "all 0.3s ease-in-out",
    '& h3': {
      transition: "all 0.3s ease-in-out",
    },
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  },
}));
